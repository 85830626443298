import { computed, watch } from "vue";

/** 区分 pc 和 mobile 模式*/
export function useViewMode() {
  const isPc = import.meta.env.VITE_VIEW_MODW == "pc";

  const isMobile = computed(() => !isPc);

  return {
    isPc,
    isMobile,
  };
}
