import { PaginationParams } from "@/type/api";
import dayjs from "dayjs";
import { getMonthRange, getWeekRangeNow } from "./time";

/** 生成 odata-query 分页参数 */
export function genOdataQueryPageOptions(pageParams?: PaginationParams) {
  const top = pageParams?.pageSize ?? 10;
  return {
    skip: (pageParams?.current ?? 0) * top,
    top,
  };
}

/** 生成一个月时间范围筛选条件 */
export function genMonthRangeQuery(year: number, month: number) {
  const [start, end] = getMonthRange(year, month);
  return [{ ge: start.toDate() }, { lt: end.toDate() }] as const;
}

/** 生成本周时间范围筛选条件 */
export function genWeekRangeQuery() {
  const [start, end] = getWeekRangeNow();
  return [{ ge: start }, { lt: end }] as const;
}
