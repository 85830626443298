import dayjs from "dayjs";

/** 获取当前一周时间范围 */
export function getWeekRangeNow() {
  return [
    dayjs().day(0).startOf("day").add(1, "day").toDate(),
    dayjs().day(6).startOf("day").add(2, "day").toDate(),
  ];
}

/** 获取某一天时间范围 */
export function getDayRange(day: string) {
  return [
    dayjs(day).startOf("day").toDate(),
    dayjs(day).add(1, "day").startOf("day").toDate(),
  ];
}

/** 生成一个月时间范围 */
export function getMonthRange(year: number, month: number) {
  const start = dayjs()
    .year(year)
    .month(month - 1)
    .startOf("month");
  const end = start.add(1, "month");
  return [start, end];
}
