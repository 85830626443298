import dayjs from "dayjs";
import { Dialog } from "vant";
import { message as antMessage } from "ant-design-vue";
import { useViewMode } from "@/hooks/useViewMode";

const { isPc } = useViewMode();
export function customNavigateBackFn() {
  const pages = getCurrentPages();
  if (pages.length === 1) {
    // history.go(-1)
    history.back();
  } else {
    uni.navigateBack();
  }
}
export function getDate(date?: any, AddDayCount = 0) {
  if (!date) {
    date = new Date();
  }
  if (typeof date !== "object") {
    date = date.replace(/-/g, "/");
  }
  const dd = new Date(date);

  dd.setDate(dd.getDate() + AddDayCount); // 获取AddDayCount天后的日期

  const y = dd.getFullYear();
  const m =
    dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1; // 获取当前月份的日期，不足10补0
  const d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(); // 获取当前几号，不足10补0
  return {
    fullDate: y + "-" + m + "-" + d,
    year: y,
    month: m,
    date: d,
    day: dd.getDay(),
  };
}
export const utcTimeInit = (e: any) => {
  var date = new Date(e);
  let ymd = dayjs(e).format("YYYY-MM-DD");
  return ymd + "T00:00:00";
};
export const HMDHMTime = (e: any) => {
  var date = new Date(e);
  let ymd = dayjs(e).format("YYYY-MM-DD");
  let hms = dayjs(e).format("HH:mm");
  return ymd + " " + hms;
};
export const utcTime = (e: any) => {
  // console.info(e, '--------------');
  var date = new Date(e);
  let ymd = dayjs(e).format("YYYY-MM-DD");
  let hms = dayjs(e).format("HH:mm:ss");
  // var isoDate = date.toISOString().substr(0, 23) + 'Z';
  // 2023-04-10T02:23:52
  return ymd + "T" + hms;
};
export const maxEndTime = (time: any) => {
  return dayjs(dayjs(time).format("YYYY-MM-DD") + "T23:59:00");
};

type TimeType = string | number | dayjs.Dayjs | Date;

/** 格式化时间 */
export const formatTime = (
  time?: TimeType,
  options: { hour?: boolean; iso?: boolean; year?: boolean } = {}
) => {
  const { hour = false, iso = false, year = true } = options;

  if (!time) return "";

  if (iso) return dayjs(time).toISOString();

  let formatString = [];
  if (year) {
    formatString.push("YYYY-MM-DD");
  }
  if (hour) {
    formatString.push("HH:mm");
  }
  return dayjs(time).format(formatString.join(" "));
};

export const tryParseJSON = (data?: string) => {
  let result: Record<string, any> | undefined;

  if (!data) return;

  try {
    result = JSON.parse(data);
  } catch (err) {}

  return result;
};

/** 获取 url 查询参数 */
export const getLocationQuery = (key: string) => {
  return new URL(location.href).searchParams.get(key);
};

export const isValidTime = (time?: string) => {
  if (!time) return false;
  return !(time.split("-")[0].length < 4);
  // return !!new Date(time)?.getTime?.();
};

// 处理相同时间
export const formatStartEndTime = (data: any) => {
  if (!data) {
    return "";
  }
  const formatTime = data.map((item: any) => {
    return dayjs(item).format("YYYY-MM-DD"); // HH:mm:ss
  });
  if (!isValidTime(formatTime[0]) || !isValidTime(formatTime[1])) return;

  if (formatTime[0] === formatTime[1] || formatTime[0] == "2001-01-01") {
    return formatTime[1];
  } else {
    return `${formatTime[0]} - ${formatTime[1]}`;
  }
};
// 过期会议判断
export const isDayLoseTime = (startTime: any, endTime: any) => {
  if (startTime) {
    let startDay = new Date(startTime).getTime() + 24 * 60 * 60 * 1000; // dayjs(startTime).format("YYYY-MM-DD");
    let endDay = new Date(endTime).getTime() + 24 * 60 * 60 * 1000 * 4; // 会议过期后按钮保留2-3天

    let today = new Date().getTime();
    return today > startDay && today <= endDay;
  } else {
    return false;
  }
};
// 会议时间是否为当日
export const isDayTime = (startTime: any, endTime: any) => {
  if (startTime) {
    let startDay = new Date(startTime).getTime() - 24 * 60 * 60 * 1000; // dayjs(startTime).format("YYYY-MM-DD");
    let endDay = new Date(endTime).getTime() + 24 * 60 * 60 * 1000 - 1; // 会议过期后按钮保留2-3天

    let today = new Date().getTime();
    return today >= startDay && today <= endDay;
  } else {
    return false;
  }
};
export const isLeDay = (startTime: any, endTime: any) => {
  if (startTime) {
    let startDay = new Date(startTime).getTime(); // dayjs(startTime).format("YYYY-MM-DD");
    let endDay = new Date(endTime).getTime() + 24 * 60 * 60 * 1000 - 1;

    let today = new Date().getTime();
    return today >= startDay;
  } else {
    return false;
  }
};
// 提示
export const message = {
  showMsg(msg: string) {
    if (isPc) {
      antMessage.warning(msg);
    } else {
      uni.showToast({
        title: msg,
        // icon: 'none',
        mask: true,
        image: "/static/yuan-gt.png",
        duration: 3000,
      });
    }
  },
  error(msg: string) {
    if (isPc) {
      antMessage.error(msg);
    } else {
      uni.showToast({
        title: msg,
        icon: "error",
      });
    }
  },
  success(msg: string) {
    if (isPc) {
      antMessage.success(msg);
    } else {
      uni.showToast({
        title: msg,
        icon: "success",
      });
    }
  },
  async confirm(
    msg: string,
    {
      showCancelButton = true,
      confirmButtonText = "确定",
      cancelButtonText = "取消",
    }: {
      showCancelButton?: boolean;
      confirmButtonText?: any;
      cancelButtonText?: any;
    } = {}
  ) {
    return Dialog.confirm({
      title: "提示",
      message: msg,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      overlay: true,
    });
    // .then(() => {
    //   return true;
    // }).catch(() => {
    //   throw "cancel";
    //   return false;
    // })
  },
  async confirmReturnFn(
    msg: string,
    {
      showCancelButton = true,
      confirmButtonText = "确定",
      cancelButtonText = "取消",
    }: {
      showCancelButton?: boolean;
      confirmButtonText?: any;
      cancelButtonText?: any;
    } = {}
  ) {
    return Dialog.confirm({
      title: "提示",
      message: msg,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      overlay: true,
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async confirmWarning(msg: string) {
    return Dialog.confirm({
      title: "提示",
      message: msg,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "关闭",
    });
  },
  pcSuccess(msg: string) {
    antMessage.success(msg);
  },
  pcError(msg: string) {
    antMessage.error(msg);
  },
  pcWarning(msg: string) {
    antMessage.warning(msg);
  },
};

// 获取时间段所有日期
export const getDateRangeAllDate = (start: string, end: string) => {
  let result = [];
  const endDay = dayjs(end);
  let startDay = dayjs(start).startOf("day");

  if (startDay.isAfter(endDay)) {
    throw "start day after end day";
  }

  while (!startDay.isSame(endDay, "day")) {
    result.push(dayjs(startDay).format("YYYY-MM-DD"));
    startDay = startDay.add(1, "day");
  }

  result.push(dayjs(startDay).format("YYYY-MM-DD"));

  return result;
};

/** 获取路径文件名 */
export const getUrlFilename = (url?: string) => {
  return url?.split("/").pop();
};

/** 获取随机数字串 */
export const getRandomNumber = () => {
  return Math.random().toString().slice(2);
};

export const returnApiData = (isPc: any, res: any) => {
  if (isPc) {
    return {
      array: res.data,
      count: res.count,
    };
  } else {
    return res.data;
  }
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  var arr: any = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const redirectFn = (redirectUrl: any) => {
  window.location.href = window.origin + redirectUrl;
};

export const openWindowFn = async (newUrl: any) => {
  //获取user-agaent标识头
  var ua = window.navigator.userAgent.toLowerCase();
  //判断ua和微信浏览器的标识头是否匹配
  // if ((ua.match(/micromessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork')) {
  //   await message.confirm('即将使用默认浏览器打开', {
  //     confirmButtonText: '继续',
  //     cancelButtonText: '取消'
  //   })
  // } else {
  // }
  window.open(newUrl);
};

export const islocationHref = () => {
  return window.location.origin;
  let origin = "";
  if (window.location.origin.indexOf("localhost") > -1) {
    origin = "https://lundbeck-event-mobile-dev.deloitte.cc";
    // 'https://event-mobile.lundbecksys.cn'
  } else {
    origin = window.location.origin;
  }
  return origin;
};
// url地址是否签名过 -- window.location.href
export const signatLocal = (url: any) => {
  let oldSignaturl = window.localStorage.getItem("SignaturlArr");
  let sArr = [];
  let hasSigned = false;
  if (oldSignaturl) {
    sArr = JSON.parse(oldSignaturl);
    let hasUrl = sArr.find((item: any) => item == url);
    if (hasUrl) {
      hasSigned = true;
    } else {
      sArr.push(url);
    }
  } else {
    sArr.push(url);
  }
  window.localStorage.setItem("SignaturlArr", JSON.stringify(sArr));
  return hasSigned;
};
/**
 *
 * @param selectedList 选择的医生列表
 * @param physicianList 已存在的医生列表
 */
export const selectNewDoctorFn = (selectedList: [], physicianList: []) => {
  let addArr: any = [];
  selectedList.forEach((item: any) => {
    // 目标医生
    if (item.physician?.physician_id) {
      let fObj = physicianList.find(
        (mp: any) => mp.physician_id == item.physician?.physician_id
      );
      if (!fObj) {
        addArr.push(item.physician);
      }
    } else {
      let fObj = physicianList.find(
        (mp: any) =>
          mp.physician_name == item.physician?.physician_name &&
          mp.institution_name == item.physician?.institution_name &&
          mp.department_name == item.physician?.department_name
      );
      if (!fObj) {
        addArr.push(item.physician);
      }
    }
  });
  return addArr;
};
export const clearLocalStore = () => {
  let arr = [
    "BaseProduct",
    "userId",
    "userinfo",
    "kvpDictionaries",
    "wxWorkId",
    "SignaturlArr",
    "__DC_STAT_UUID",
    "awsConfig",
    "signFail",
  ];
  for (var key in localStorage) {
    if (key != "openid") {
      window.localStorage.removeItem(key);
    }
  }
};
export const hideTelPhone = (phone: any) => {
  if (phone) {
    return phone.slice(0, 3) + "****" + phone.slice(7, 11);
  } else {
    return phone;
  }
};
export const hideBankName = (branchName: any) => {
  if (branchName) {
    let len = branchName.length;
    return branchName.slice(0, 4) + "****" + branchName.slice(len - 4, len);
  } else {
    return branchName;
  }
};
export const hideIdcard = (idCard: any) => {
  if (idCard) {
    return idCard.replace(/(.{6}).*(.{4})/, "$1********$2");
  } else {
    return idCard;
  }
};
export const hideName = (name: any) => {
  if (name) {
    return name.replace(/(?<=.)./g, "*");
  } else {
    return name;
  }
};
export const convertToChineseUppercase = (e: number) => {
  const fraction = ["角", "分"];
  const digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  const unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"],
  ];

  let num = Math.abs(e);
  let s = "";
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(num * 10 * 10 ** index) % 10] + item).replace(
      /零./,
      ""
    );
  });
  s = s || "整";
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i++) {
    let p = "";
    for (let j = 0; j < unit[1].length && num > 0; j++) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return e < 0 ? `负${s}` : e == 0 ? "零元" : s;
};

export const mtPrepareFn = (res: any) => {
  if (res.meeting_prepare) {
    res.meeting_prepare.book_venue_plan = JSON.parse(
      res.meeting_prepare.book_venue_plan
    );
    res.meeting_prepare.book_venue_setting = JSON.parse(
      res.meeting_prepare.book_venue_setting
    );
    res.meeting_prepare.evaluation_setting = JSON.parse(
      res.meeting_prepare.evaluation_setting
    );
    res.meeting_prepare.invitation_plan = JSON.parse(
      res.meeting_prepare.invitation_plan
    );
    res.meeting_prepare.invitation_setting = JSON.parse(
      res.meeting_prepare.invitation_setting
    );
    res.meeting_prepare.introduction_setting = JSON.parse(
      res.meeting_prepare.introduction_setting
    );
    res.meeting_prepare.before_questionnaire_setting = JSON.parse(
      res.meeting_prepare.before_questionnaire_setting
    );
    res.meeting_prepare.poster_plan = JSON.parse(
      res.meeting_prepare.poster_plan
    );
    res.meeting_prepare.poster_setting = JSON.parse(
      res.meeting_prepare.poster_setting
    );
    res.meeting_prepare.remind_setting = JSON.parse(
      res.meeting_prepare.remind_setting
    );
    res.meeting_prepare.sign_in_setting = JSON.parse(
      res.meeting_prepare.sign_in_setting
    );

    if (!res.meeting_prepare.remind_setting.reminds) {
      res.meeting_prepare.remind_setting.reminds = [];
    }
  }
  if (res.meeting) {
    let startTime = formatTime(res.meeting.meeting_start);
    let endTime = formatTime(res.meeting.meeting_start);
    let today = new Date().getTime(); // 当前时间
    let endDay = new Date(res.meeting.meeting_start).getTime(); // 结束时间
    if (today < endDay) {
      startTime = dayjs(today).format("YYYY-MM-DD");
    }
    let prepareObj = res.meeting_prepare;
    if (prepareObj) {
      if (!prepareObj.book_venue_plan.plan_start_time) {
        // 会场准备
        prepareObj.book_venue_plan = {
          plan_start_time: startTime,
          plan_end_time: endTime,
        };
        prepareObj.bookVenuePlan = [startTime, endTime];
      } else {
        prepareObj.bookVenuePlan = [
          formatTime(prepareObj.book_venue_plan.plan_start_time),
          formatTime(prepareObj.book_venue_plan.plan_end_time),
        ];
      }
      if (!prepareObj.invitation_plan.plan_start_time) {
        // 参会者邀请
        prepareObj.invitation_plan = {
          plan_start_time: startTime,
          plan_end_time: endTime,
        };
        prepareObj.invitationPlan = [startTime, endTime];
      } else {
        prepareObj.invitationPlan = [
          formatTime(prepareObj.invitation_plan.plan_start_time),
          formatTime(prepareObj.invitation_plan.plan_start_time),
        ];
      }
      return prepareObj;
    }
  }
  return res.meeting_prepare;
};

export const getYesterdayDate = (date?: Date | string) => {
  if (!date) return;
  if (typeof date === "string") {
    date = new Date(date);
  }
  const yesterday = new Date(date);
  yesterday.setDate(date.getDate() - 1);
  return yesterday;
};
